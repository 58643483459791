import { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import Modal from 'react-modal';
import AppointmentScheduler from './AppointmentScheduler';
import EmailRegistration from './EmailRegistration';

function SignUpForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTurnstileValid, setTurnstileValid] = useState(false);

  return (
    <div className="font-semibold">
      { /* -- <iframe width="420" height="315"
        src="https://www.youtube.com/embed/tRHhyocsbv8">
      </iframe> --> */}
      <div className="pt-4 grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex items-center">
          <div>
            <div>
                Love is the reason we are here! For better or worse, dating apps have become the most used method to find love. We want to talk with actual people about their experiences on Dating Apps. Sign up for a single brief virtual interview to share your experiences with us to better help all find love.
            </div>
            <div class="flex justify-center">
              <button
                className="btn btn-primary"
                onClick={() => setIsModalOpen(true)}>
                
                Participate in Interview 
              </button>
            </div>
            <Modal
              isOpen={isModalOpen}
              contentLabel="Example Modal"
            >
              <button
                className="btn btn-secondary float-right"
                onClick={() => setIsModalOpen(false)}>
                
                X
              </button>
              
              <AppointmentScheduler isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid}/>
            </Modal>
          </div>
        </div>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex items-center">
        <div>
          <div>
            Are we a bit crazy for this idea?!?! We think maybe it’s possible folks might even want to spare several bucks here. Since we're just two people starting from scratch to try to re-invent the dating game, we have a bit of a hill to overcome. While contributions are by no means necessary nor expected, they're something that could help invigorate our dream. Even a contribution as small as $1 goes a long way. Any amount would make us ecstatically appreciative of your gesture.
            <br/>
            <br/>
            For your support to help all find love, you’ll have your name placed on our Original Benefactors page forever.
          </div>
          <div class="flex justify-center">
            <a
                className="btn btn-primary"
                href="https://venmo.com/u/uheart"
                target="_blank">
                
                
                Contribute on Venmo
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex items-center">
          <EmailRegistration isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid} />
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;