import { useState, useEffect } from 'react';
import isValidEmail from './EmailValidator.js';
import { Turnstile } from '@marsidev/react-turnstile';

function EmailRegistration({isTurnstileValid, setTurnstileValid}) {
	const [email, setEmail] = useState('');
	const [showSubmitForm, setshowSubmitForm] = useState(true);
	const [showTurnstile, setShowTurnstile] = useState(true);
	const [buttonText, setButtonText] = useState("Join Email List");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [turnstileToken, setTurnstileToken] = useState('');
	const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(true);
	const [responseText, setResponseText] = useState(null);
	const [emailAdded, setEmailAdded] = useState(false);
	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [email]);

	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [termsAndConditionsChecked]);

	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [turnstileToken]);

	const addEmailToList = () => {
		let formData = new FormData();
		formData.append('token', turnstileToken);
		console.log(email);
		(async () => {
			const response = await fetch("/AddEmailToList?emailToAdd=" + email, {
			method: "POST",
			body: formData
			});
			setEmailAdded(true);
			setResponseText(response.statusText);
		})();
	};

	function emailSubmitted() {
		setResponseText('Submitting...');
		setshowSubmitForm(false);
		addEmailToList();
	}

	function turnstileCallback(token) {
		setTurnstileToken(token);
	}

	function emailInputChanged(e) {
		setEmail(e.target.value);
	}

	function termsAndConditionsToggled() {
		setTermsAndConditionsChecked(!termsAndConditionsChecked);
	}

	function shouldSubmitButtonBeEnabled() {
		if(email && isValidEmail(email) && termsAndConditionsChecked && turnstileToken !== '') {
			return true;
		} else {
			return false;
		}
	}

	return (
		<div className="mb-4">
			<include src="https://challenges.cloudflare.com/turnstile/v0/api.js"></include>
			<div className="py-2 px-4 m-4 text-center" style={{ display: responseText ? 'block' : 'none' }} >
				{responseText}
			</div>
			<div style={{ display: showSubmitForm ? 'block' : 'none'}} >
				<label className="block mb-2">
					Along with following our social media, we’re happy to provide you updates by email. Enter your email to stay updated with what we have to share in innovating the dating world!
				</label>
				<input
				className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				value={email}
				onChange={emailInputChanged}
				id="email"
				type="text"
				placeholder="Email"/>
				{/** <div className="left-section pt-2">
					<input
						type="checkbox"
						id='terms-and-conditions'
						name={termsAndConditionsChecked}
						onChange={termsAndConditionsToggled}
						required />
					<label className="required-field ml-2">Agree to our Terms and Conditions you blowhard</label>
				</div>*/}
				<div className="text-center">
					<button
						className="btn btn-primary"
						onClick={emailSubmitted}
						disabled={isButtonDisabled} >
						
						{buttonText}
					</button>
				</div>
			</div>
			<div className="flex justify-center pt-2" style={{ display: showSubmitForm	? 'flex' : 'none' }}>
				<Turnstile siteKey="0x4AAAAAAAjstFUDnC14PVv6" onSuccess={turnstileCallback} />
			</div>
		</div>
	);
}

export default EmailRegistration;