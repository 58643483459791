import './output.css';

function TermsAndConditions() {
	return (
		<div class="min-h-dvh overflow-visible border-t border-l border-r border-gray-400 px-3 py-10 bg-gradient-to-br from-primary to-secondary flex justify-center">
			<div class="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
	        	
	        </div>
	    </div>
	);
}

export default TermsAndConditions;
